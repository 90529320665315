import {
    USERNAME_ERRORS,
    PASSWORD_ERRORS,
    // DRIVER_ERRORS, EXT_ERRORS,
    // OTP_ERRORS, DROP_TRAILER_ERROR,
    // NAME_ERRORS, LICENSE_ERRORS,
    // LANGUAGE_ERRORS, VALUE_ERROR
} from "./constants/validationMessages";
const validator = {
    // username: {
    //     rules: [{
    //         test: value => {
    //             return value.trim().length > 0;
    //         },
    //         message: USERNAME_ERRORS.blankFieldError
    //     }
    //     ],
    //     errors: [],
    //     valid: false,
    //     state: ""
    // },
    // password: {
    //     rules: [{
    //         test: value => {
    //             return value.length > 0;
    //         },
    //         message: PASSWORD_ERRORS.blankFieldError
    //     }
    //     ],
    //     errors: [],
    //     valid: false,
    //     state: ""
    // },
    phoneNumber: {
        rules: [{
            test: value => {
                const regex = new RegExp(/[^0-9]/g)
                return !value.match(regex);
            },
            message: "Mobile number must contain only numbers."
        },
        {
            test: value => {
                return value.length === 10;
            },
            message: "Mobile number must contain 10 digits."
        }
        ],
        errors: [],
        valid: false,
        state: ""
    },
    pin: {
        rules: [{
            test: value => {
                const regex = new RegExp(/[^0-9]/g)
                return !value.match(regex);
            },
            message: "OTP number must contain only numbers."
        },
        {
            test: value => {
                return value.length === 6;
            },
            message: "OTP number must contain 6 digits."
        }
        ],
        errors: [],
        valid: false,
        state: ""
    }
    // driverName: {
    //     rules: [{
    //             test: value => {
    //                 return value.length > 0;
    //             },
    //             message: DRIVER_ERRORS.blankFieldError
    //         }
    //     ],
    //     errors: [],
    //     valid: false,
    //     state: ""
    // }
}
export {
    validator
};
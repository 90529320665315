import React, { Component } from 'react';
import { CallApi } from '../../services/service';
import { validator } from '../../validator';
import { CONFIG } from '../../configuration/config';
import * as ROUTES from '../../constants/routes';
import { spinnerName } from '../../constants/data';
import { spinnerService } from '../../services/spinner.service';
import LanguageUtils from '../../utils/LanguageUtils';
import { setData, getData, removeData } from "../../containers/storageHandler";
import AlertModal from '../../common/modals/AlertModal';
import Header from '../../common/Header';
import '../../global.css';

import {
  isFormValid,
  resetValidators,
  updateValidators,
  displayValidationErrors
} from '../../containers/formValidator';

class LandingComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      phoneNumber: '',
      pin: '',
      pinIsSent: false
    };
    resetValidators(validator);
  }
  
  //   var validators= '';
  handleInputChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
    updateValidators(event.target.name, event.target.value, validator);
  };

  handleSubmit = event => {
    event.preventDefault();

    const loginObj = {
      userPhoneNumber: '1' + this.state.phoneNumber,
      pin: this.state.pin,
      username: '',
      password: ''
    }
    const langType = LanguageUtils.getLangType(this.props);

    if (!this.state.pinIsSent) {
      spinnerService.show(spinnerName.LOGIN_SPINNER);
      CallApi('POST', CONFIG.sendSMSPin, {}, loginObj, langType, "updatePinState", this);
    } else {
      setData('userPhoneNumber', this.state.phoneNumber)
      spinnerService.show(spinnerName.LOGIN_SPINNER);
      CallApi('POST', CONFIG.authorizeSms, {}, loginObj, langType, "authorizeLogin", this);
    }
  };

  updatePinState = () => {
    this.setState({
      pinIsSent: true
    })
  }

  authorizeLogin = (response) => {
    // const dataObj = {
    //   facilityId: '109',
    //   facilityCode: 'PHI',
    //   laneNo: '1',
    //   plantType: 'MA',
    //   skipInbound: 'N',
    //   showFacilityAndLane: true
    // };
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    // setData('facility', {
    //   facilityId: params.facilityId,
    //   facilityCode: params.facilityCode,
    //   laneNo: params.laneNo,
    // })
    setData('facility', {
      facilityId: "109",
      facilityCode: "PHI",
      laneNo: "1",
    })
    setData('auth', response.result.token);
    setData('isLogin', true);
    removeData('configureData')
    // setData('configureData', dataObj);
    removeData('pageName');
    // setData('pageName', 'checkin');
    this.props.history.push(ROUTES.CONFIGURATION);
    // this.props.history.push(ROUTES.CHECKINGATEPASS);
  }

  render() {
    return (
      <React.Fragment>
        <Header history={this.props.history} />
        <div className="login-page">
          <div className="body-container">
            <section className="login-form-section">
              <div className="d-flex flex-column justify-content-center login-form">
                <form
                  autoComplete="off"
                  onSubmit={this.handleSubmit}
                >
                  {this.state.pinIsSent ? 
                  <div className="d-flex flex-column">
                    <span className="form-title">
                      Verification Code
                    </span>
                    <span className="form-subtitle">
                      Please enter the code that was sent to your phone
                    </span>
                    <div className="input-group">
                      <input
                        type="text"
                        pattern="[0-9]*"
                        inputmode="numeric"
                        className="form-control"
                        name="pin"
                        value={this.state.pin}
                        onChange={this.handleInputChange}
                        placeholder="OTP Code"
                        autoComplete="off"
                        autoFocus={true}
                        autocomplete="one-time-code"
                      />
                    </div>                    
                    {displayValidationErrors('pin', validator)}
                  </div> :
                  <div className="d-flex flex-column">
                    <span className="form-title">
                      Mobile Number
                    </span>
                    <span className="form-subtitle">
                      Please enter your mobile number to log in
                    </span>
                    <div className="input-group">
                      <input
                        type="tel"
                        className="form-control text-center"
                        name="phoneNumber"
                        value={this.state.phoneNumber}
                        onChange={this.handleInputChange}
                        placeholder="(123) 456 7890"
                        autoComplete="off"
                        autoFocus={true}
                      />
                    </div>
                    {displayValidationErrors('phoneNumber', validator)}
                  </div>}
                  <div className="text-center d-flex justify-content-center my-4">
                    <button
                      type="submit"
                      name="Login"
                      className="btn btn-dark md-button"
                      disabled={(this.state.phoneNumber.length !== 10 && this.state.pin.length === 0) || (this.state.pinIsSent && this.state.pin.length !== 6)}
                      value="Login"
                    >
                      <span className='text-white'>
                        {this.state.pinIsSent ? 'Verify' : 'Login'}
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default LandingComponent;

import React, { Component } from 'react';
import { CONFIG } from '../../configuration/config';
import { CallApi } from '../../services/service';
import * as ROUTES from '../../constants/routes';
import PageProgressComponent from '../../common/PageProgressComponent';
import { connect } from 'react-redux';
import * as dataAction from '../../redux/actions/dropTrailerAction';
import propTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { spinnerName } from '../../constants/data';
import { spinnerService } from '../../services/spinner.service';
import * as LANGCONSTANT from '../../constants/language';
import LanguageUtils from '../../utils/LanguageUtils';
import Header from '../../common/Header';
import IdleTimerComponent from '../../common/idleCheck/IdleTimerComponent';
import Select from 'react-select';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import Modal from 'react-modal';
import AlertModal from "../../common/modals/AlertModal";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

class DropTrailerEnterDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            carrierList: [],
            trailerTypeList: null,
            trailerSizeList: [],
            optionList: ['Yes', 'No'],
            liftGate: 'no',
            trailerId: '',
            trailerSize: '',
            driverName: '',
            mobileNumber: '',
            licensePlateNumber: '',
            trailerNumber: '',
            prePath: '',
            carrier: '',
            pageNumber: 1,
            verifyModalIsOpen: false,
            pageNameList: [
                LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanGatePass,
                LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.AcceptTC,
                LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.Result
            ],
            layoutName: "default",
            inputName: "input1",
            input: {}
        };
    }

    componentWillMount() {
        if(this.props.location.state && this.props.location.state.checkFlow){
            this.setState({
                driverName: this.props.masterData ? this.props.masterData.delivery[this.props.location.state.index][0].trailer_details.driver_name : '',
                // mobileNumber: this.props.masterData ? this.props.masterData.delivery[this.props.location.state.index][0].trailer_details.driver_phone : '',
                licensePlateNumber: '',
                trailerNumber: this.props.masterData ? this.props.masterData.delivery[this.props.location.state.index][0].trailer_details.trailer_number : '',
            })
        }
        this.getCarrierInfo();
    }

    openVerifyModal() {
        this.setState({
            verifyModalIsOpen: true,
        });
    }

    closeVerifyModal() {
        this.setState({
            verifyModalIsOpen: false
        });
    }

    afterVerifyOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#000000';
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location !== this.props.location) {
            this.setState({ prevPath: this.props.location })
        }
    }

    getCarrierInfo() {
        const langType = LanguageUtils.getLangType(this.props);
        spinnerService.show(spinnerName.LOGIN_SPINNER);
        CallApi("GET", CONFIG.getCarrierInfo, {}, {}, langType, "returnGetCarrierInfo", this);
    }

    returnGetCarrierInfo = response => {
        const langType = LanguageUtils.getLangType(this.props);
        if (response.success) {

            // Remove it after api changes
            // response.result.trailer_type['ENG'][1].trailerId = "2";
            // response.result.trailer_type['ESP'][1].trailerId = "2";

            this.setState({
                carrierList: response.result.carriers,
                trailerTypeList: response.result.trailer_type,
                trailerSizeList: response.result.trailer_size
            });

            if (response.result.trailer_type && response.result.trailer_type[langType]) {
                this.setState({
                    trailerId: response.result.trailer_type[langType][1]['trailerId'],
                })
            }
            if(response.result.trailer_size){
                this.setState({
                    trailerSize: response.result.trailer_size[2].value
                })
            }
        }
    }

    checkNumber(event) {
        if (!(event.charCode > 47 && event.charCode <= 57)) {
            event.preventDefault();
        }
    }

    handleDataChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleOptionChange(event) {     
        this.state = {
            input: {
                input5: ''
            }
        }
        this.setState({
            [event.name]: event.value
        });
        this.keyboardRef.keyboard.setInput('');
        
    }

    optionData() {
        var optionData = [];
        this.state.carrierList.forEach(data => {
            optionData.push({ value: data.carrierName, label: data.displayName, name: 'carrier' })
        });
        return optionData;
    }

    back() {
        this.props.history.goBack();
    }

    verifyMobileNumber() {
        const langType = LanguageUtils.getLangType(this.props);
        if (this.state.mobileNumber && this.state.carrier && this.state.driverName && this.state.trailerNumber && this.state.trailerId && this.state.trailerSize) {
            if (this.state.mobileNumber.length === 10 && this.state.mobileNumber.match("^[0-9]*$")) {
                this.openVerifyModal();
            } else {
                AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.ValidMobile);
            }
        } else {
            AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.EnterDetails)
        }
    }

    getTrailerType(lang) {
        if (this.state.trailerTypeList && this.state.trailerTypeList[lang]) {
            const trailerDetails = this.state.trailerTypeList[lang].find(val => val.trailerId === this.state.trailerId);
            return trailerDetails && trailerDetails['trailerType'] || '';
        }
        return '';
    }

    handleConfirm() {
        const langType = LanguageUtils.getLangType(this.props);
        const dropTrailerObj = {
            carrier_scac: this.state.carrier,
            driver_name: this.state.driverName,
            // phone_no: this.state.mobileNumber,
            licensePlateNumber: this.state.licensePlateNumber,
            trailer_number: this.state.trailerNumber,
            trailer_type: this.getTrailerType(langType),
            trailer_length: this.state.trailerSize,
            liftGate: this.state.liftGate,
            lane_no: this.props.checkinData ? this.props.checkinData.laneNo : '',
            language: this.props.languageData ? this.props.languageData.language : 'en',
            visit_type: 'DE',
            org_id: this.props.checkinData ? this.props.checkinData.facilityId : '',
            reference_id: ''
        }
        if (this.state.driverName && this.state.carrier && this.state.mobileNumber && this.state.trailerNumber && this.state.trailerId && this.state.trailerSize && this.state.liftGate) {
            // this.context.router.replace(this.props.location.state.ROUTES.TERMSANDCONDITION)
            this.props.actions.dropTrailerData(dropTrailerObj);
            this.props.history.push({
                pathname: ROUTES.TERMSANDCONDITION,
                state: {
                    detail: ROUTES.DROPTRAILERS,
                    checkFlow: (this.props.location.state && this.props.location.state.checkFlow) ?
                        this.props.location.state.checkFlow : false,
                    reference_id: (this.props.location.state && this.props.location.state.reference_id) ?
                        this.props.location.state.reference_id : '',
                    index: (this.props.location.state && this.props.location.state.index),
                    didQueryReturnTrailerNumber: (this.props.location.state && this.props.location.state.didQueryReturnTrailerNumber)? true:false
                }
            });
        } else {
            AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.EnterDetails)
        }
    }
// -------------------------------------------------------------------------------------------

handleChange(event) {
    let inputVal = event.target.value;

    let updatedInputObj = {
      ...this.state.input,
      [this.state.inputName]: inputVal
    };

    this.setState(
      {
        input: updatedInputObj
      },
      () => {
        this.keyboardRef.keyboard.setInput(inputVal);
      }
    );
  }

  onChangeAll = inputObj => {
    this.setState({
      input: inputObj,
      mobileNumber: inputObj.input2,
      driverName: inputObj.input3,
      trailerNumber: inputObj.input4
    });
  };

  onKeyPress = button => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") this.handleShift();
    if (button === "{enter}") this.verifyMobileNumber();
  };

  handleShift = () => {
    let layoutName = this.state.layoutName;

    this.setState({
      layoutName: layoutName === "default" ? "shift" : "default"
    });
  };

  setActiveInput = inputName => {
    this.setState(
      {
        inputName: inputName
      },
      () => { }
    );
  };

    render() {
        const langType = LanguageUtils.getLangType(this.props);
        return (
            <div>
                {/* <IdleTimerComponent /> */}
                <Header history={this.props.history} />
                <div className="body-container  keyboard-container">
                    <section>
                        <div className="checkout-process-tabber">
                            <div className="checkout-process-wrapper">
                                <div className="checkout-process-container container-890 white-box delivery-detail">
                                    <h4>{LANGCONSTANT[langType].LABELS.EnterDetails}yeet</h4>

                                    <form action="" className="d-flex flex-wrap">
                                        <div className="form-group">
                                            <Select
                                                classNamePrefix='custom-select'
                                                className="form-control"
                                                isSearchable={true}
                                                openMenuOnClick={true}
                                                menuIsOpen={this.state.input.input5? true : false}  
                                                components={{ DropdownIndicator: () => null,IndicatorSeparator: () => null }}
                                                onFocus={() => this.setActiveInput("input5")}
                                                noOptionsMessage={() => LANGCONSTANT[langType].STRINGS.NoRecordFound}
                                                placeholder={LANGCONSTANT[langType].LABELS.SelectCarrier}
                                                name='carrier'
                                                onChange={event => this.handleOptionChange(event)}
                                                options={this.optionData()}
                                                openMenuOnFocus={true}
                                                autoFocus={true}
                                                inputValue={this.state.input.input5}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                onFocus={() => this.setActiveInput("input2")}
                                                // placeholder={LANGCONSTANT[langType].LABELS.PhoneNumber}
                                                placeholder="License Plate Number"
                                                value={this.state.input["input2"] || ""}
                                                maxLength='10'
                                                name='License Plate Number'
                                                className="form-control"
                                                onChange={(event) => this.handleChange(event)}
                                                onKeyPress={(event) => this.checkNumber(event)}
                                                 />
                                        </div>
                                        <div className="form-group">
                                            <input
                                            type="text"
                                            onFocus={() => this.setActiveInput("input3")}
                                            placeholder={LANGCONSTANT[langType].LABELS.DriverName}
                                            value={this.state.input["input3"] || ""}
                                            className="form-control"
                                            name='driverName'
                                            onChange={(event) => this.handleChange(event)} />
                                        </div>
                                        <div className="form-group">
                                            <input
                                            type="text"
                                            onFocus={() => this.setActiveInput("input4")}
                                            placeholder={LANGCONSTANT[langType].LABELS.TrailerNumber}
                                            value={this.state.input["input4"] || ""}
                                            className="form-control"
                                            name='trailerNumber'
                                            onChange={(event) => this.handleChange(event)} />
                                        </div>
                                        <div className="form-group d-flex">
                                            <strong>{LANGCONSTANT[langType].LABELS.TrailerType}</strong>
                                            <select
                                            name='trailerId'
                                            className="form-control"
                                            onChange={(event) => this.handleDataChange(event)}
                                            value={this.state.trailerId}>
                                                {
                                                    this.state.trailerTypeList && this.state.trailerTypeList[langType].map((data, index) =>
                                                        <option key={data.trailerId}
                                                            value={data.trailerId}
                                                        >{data.trailerType}</option>, this)
                                                }
                                            </select>
                                            <strong>{LANGCONSTANT[langType].LABELS.TrailerSize}</strong>
                                            <select name='trailerSize' className="form-control" onChange={(event) => this.handleDataChange(event)} value={this.state.trailerSize}> 
                                                {
                                                    this.state.trailerSizeList.map((data, index) =>
                                                        <option key={index} value={data.value}>{data.value}</option>, this)
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <strong>{LANGCONSTANT[langType].LABELS.LiftGate}</strong>
                                            <label className="radio-group"><input type="radio" value='yes' onChange={(event) => this.handleDataChange(event)} name='liftGate' /> {LANGCONSTANT[langType].BUTTONS.Yes}</label>
                                            <label className="radio-group"><input type="radio" value='no' onChange={(event) => this.handleDataChange(event)} name='liftGate' defaultChecked />{LANGCONSTANT[langType].BUTTONS.No}</label>
                                        </div>
                                        <div className="btn-wrapper text-right">
                                            <div className="btn btn-disable" onClick={() => this.back()}>{LANGCONSTANT[langType].BUTTONS.Back}</div>
                                            <div className="btn btn-submit" onClick={() => this.verifyMobileNumber()}>{LANGCONSTANT[langType].BUTTONS.Confirm}</div>
                                        </div>
                                    </form>
                                    <div className="keyboard d-flex">
                                        <Keyboard
                                        ref={r => (this.keyboardRef = r)}
                                        inputName={this.state.inputName}
                                        syncInstanceInputs={true}
                                        display={{
                                            "{bksp}": "backspace",
                                            "{enter}": " enter",
                                            "{space}": "space"
                                        }}
                                        layout={{
                                            default: [
                                            // "1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                                            "q w e r t y u i o p {bksp}",
                                            "a s d f g h j k l {enter}",
                                            "z x c v b n m",
                                            "{space}"
                                            ]
                                        }}
                                        onChangeAll={inputObj => this.onChangeAll(inputObj)}
                                        onKeyPress={button => this.onKeyPress(button)}
                                        />
                                        <div className="keyboard2-container">
                                        <Keyboard
                                            syncInstanceInputs={true}
                                            inputName={this.state.inputName}
                                            baseClass={"keyboard2"}
                                            layout={{
                                            default: ["1 2 3", "4 5 6", "7 8 9", "0"]
                                            }}
                                            onChangeAll={inputObj => this.onChangeAll(inputObj)}
                                            onKeyPress={button => this.onKeyPress(button)}
                                        />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className="progress-bar-bottom">
                        <PageProgressComponent
                            pageName={LANGCONSTANT[langType].LABELS.DropTrailers}
                            pageNumber={this.state.pageNumber}
                            totalPage={3}
                            pageNameList={
                                [
                                    LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanGatePass,
                                    LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.AcceptTC,
                                    LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.Result
                                ]
                            }
                        />
                    </div>
                </div>
                <div>
                    <Modal
                        isOpen={this.state.verifyModalIsOpen}
                        onAfterOpen={() => this.afterVerifyOpenModal()}
                        onRequestClose={() => this.closeVerifyModal()}
                        style={customStyles}
                        ariaHideApp={false}
                        contentLabel="Example Modal"
                    >
                        <div className="modal confirmation-modal-dialog">
                            <div className="delivery-access-body">
                                <p ref={subtitle => (this.subtitle = subtitle)}>
                                    {LANGCONSTANT[langType].STRINGS.ConfirmNumber}
                                </p>
                                <div className="form-group" style={{ textAlign: "center" }}>
                                    {this.state.mobileNumber}
                                    <p>{LANGCONSTANT[langType].STRINGS.SendNotification}</p>
                                </div>
                                <div className="d-flex">
                                    <button
                                        className="btn btn-disable"
                                        onClick={() => this.closeVerifyModal()}
                                    >
                                        {LANGCONSTANT[langType].BUTTONS.Cancel}
                                    </button>
                                    <button
                                        className="btn btn-submit"
                                        onClick={() => this.handleConfirm()}
                                    >
                                        {LANGCONSTANT[langType].BUTTONS.Confirm}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}

DropTrailerEnterDetails.propTypes = {
    actions: propTypes.object.isRequired
};

function mapStateToProps(state) {
    const data = state.data;
    return data;
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(dataAction, dispatch)
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DropTrailerEnterDetails);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import * as ROUTES from '../../constants/routes';
import { bindActionCreators } from 'redux';
import * as dataAction from '../../redux/actions/languageAction';
import * as guardAction from '../../redux/actions/guardAction';
import * as LANGCONSTANT from '../../constants/language';
import LanguageUtils from '../../utils/LanguageUtils';
import { setData, getData } from "../../containers/storageHandler";
import Header from '../../common/Header';
import { CallApi } from '../../services/service';
import { CONFIG } from '../../configuration/config';

class LanguageComponent extends Component {
  constructor(props) {
    super(props);
  }

  async handleLanguage(lang) {
    const dataObj = {
      guard: false
    };
    setData('guard', true);
    this.props.guardAction.guardData(dataObj);
    if (lang === 'en') {
      const languageData = {
        language: 'en',
        langType: 'ENG'
      };
      setData('languageData', languageData);
      this.props.actions.languageData(languageData);
    } else if (lang === 'es') {
      const languageData = {
        language: 'es',
        langType: 'ESP'
      };
      setData('languageData', languageData);
      this.props.actions.languageData(languageData);
    }
    this.props.history.push(ROUTES.CHECKINGATEPASS);
    // if (!!getData('pageName')) {
    //   const pagename = getData('pageName').pageName;
    //   if (pagename === 'checkin') {
    //     this.props.history.push(ROUTES.CHECKINGATEPASS);
    //   } else if (pagename === 'checkout') {
    //     this.props.history.push(ROUTES.EXITGATE);
    //   } else {
    //     this.props.history.push(ROUTES.CONFIGURATION);
    //   }
    // } else {
    //   this.props.history.push(ROUTES.CONFIGURATION);
    // }
  }

  render() {
    const langType = LanguageUtils.getLangType(this.props);
    return (
      <div>
        <Header history={this.props.history} showHome={true} showLang={true} />
        <div className="body-container">
          <section>
            <div className="language-section text-center d-flex justify-content-center align-items-center flex-column">
              <h4>{LANGCONSTANT[langType].STRINGS.SelectLanguage}</h4>
              <div className="btn-wrapper">
                <a
                  href="javascript:void(0)"
                  value="en"
                  onClick={() => this.handleLanguage('en')}
                  className="btn btn-disable"
                >
                  {LANGCONSTANT[langType].LABELS.English}
                </a>
                <a
                  href="javascript:void(0)"
                  value="es"
                  onClick={() => this.handleLanguage('es')}
                  className="btn btn-disable"
                >
                  {LANGCONSTANT[langType].LABELS.Spanish}
                </a>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

LanguageComponent.propTypes = {
  actions: propTypes.object.isRequired,
  guardAction: propTypes.object.isRequired
};

function mapStateToProps(state) {
  const data = state.data;
  return data;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(dataAction, dispatch),
    guardAction: bindActionCreators(guardAction, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageComponent);

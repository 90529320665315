import React, { Component } from 'react';
import { CONFIG } from '../../configuration/config';
import { CallApi } from '../../services/service';
import * as ROUTES from '../../constants/routes';
import { connect } from 'react-redux';
import * as dataAction from '../../redux/actions/deliveryDataAction';
import propTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import PageProgressComponent from '../../common/PageProgressComponent';
import { spinnerName } from '../../constants/data';
import { spinnerService } from '../../services/spinner.service';
import Header from '../../common/Header';
import * as LANGCONSTANT from '../../constants/language';
import LanguageUtils from '../../utils/LanguageUtils';
import IdleTimerComponent from '../../common/idleCheck/IdleTimerComponent';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import AlertModal from "../../common/modals/AlertModal";
import Modal from 'react-modal';
import NavButtons from "../../common/NavButtons";
import TermsAndConditionsComponent from '../termsAndConditions/TermsAndConditionsComponent';
import {getData} from '../../containers/storageHandler'


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};
class CheckinDeliveryDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carrierList: '',
      trailerTypeList: null,
      trailerSizeList: [],
      optionList: ['Yes', 'No'],
      liftGate: 'no',
      trailerType: 'Dry Van',
      trailerId: '',
      trailerSize: '',
      driverName: '',
      mobileNumber: '',
      licensePlateNumber: '',
      trailerNumber: '',
      prePath: '',
      pageNumber: 3,
      modalMessage: '',
      verifyModalIsOpen: false,
      layoutName: "default",
      inputName: "input1",
      input: {},
      vistType: '',
      openTermConditionPage:false,
      index:''
    };

    this.openVerifyModal = this.openVerifyModal.bind(this);
    this.afterVerifyOpenModal = this.afterVerifyOpenModal.bind(this);
    this.closeVerifyModal = this.closeVerifyModal.bind(this);
  }

  componentDidMount() {
    this.getCarrierInfo();
    const mobileNumber = getData('userPhoneNumber')
    let index = this.props.location.state.index || 0;
    this.setState({
      driverName: this.props.masterData ? this.props.masterData.delivery[index][0].trailer_details.driver_name : '',
      mobileNumber: mobileNumber,
      trailerNumber: this.props.masterData ? this.props.masterData.delivery[index][0].trailer_details.trailer_number : '',
      vistType: this.props.masterData ? this.props.masterData.delivery[index][0].visit_type : ''
    }, () => { }
    );
  }

  openVerifyModal() {
    this.setState({
      verifyModalIsOpen: true,
    });
  }

  closeVerifyModal() {
    this.setState({
      verifyModalIsOpen: false
    });
  }

  afterVerifyOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = '#000000';
  }

  getCarrierInfo() {
    const language = this.props.languageData ? this.props.languageData.langType : 'ENG';
    spinnerService.show(spinnerName.LOGIN_SPINNER);
    CallApi('GET', CONFIG.getCarrierInfo, {}, {}, language, "returnGetCarrierInfo", this);
  }

  returnGetCarrierInfo = response => {
    const langType = LanguageUtils.getLangType(this.props);
    if (response.success) {
      spinnerService.hide(spinnerName.LOGIN_SPINNER);

      // Remove it after api changes
      response.result.trailer_type['ENG'][1].trailerId = "2";
      response.result.trailer_type['ESP'][1].trailerId = "2";
      this.setState({
        carrierList: response.result.carriers,
        trailerTypeList: response.result.trailer_type,
        trailerSizeList: response.result.trailer_size
      });
      if (response.result.trailer_type && response.result.trailer_type[langType]) {
        this.setState({
          trailerId: response.result.trailer_type[langType][1]['trailerId'],
        });
      }
      if(response.result.trailer_size){
        this.setState({
            trailerSize: response.result.trailer_size[2].value
        })
      }
    }
  };

  handleDataChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  handleMobileChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  checkNumber(event) {
    if (!(event.charCode > 47 && event.charCode <= 57)) {
      event.preventDefault();
    }
  }

  back() {
    this.props.history.goBack();
  }

  verifyMobileNumber(e) {
    e.preventDefault()
    const langType = LanguageUtils.getLangType(this.props);
    let indexValue = this.props.location.state.index || '0';
    let flag = this.props.location.state.flag;
    const { input, mobileNumber, driverName, trailerNumber, trailerId, trailerSize, vistType } = this.state;
    if ((input.input1 && input.input3 && trailerId && trailerSize) || (vistType === "PL" && (this.props.masterData && this.props.masterData.delivery[indexValue][0].trailer_details.trailer_number) && mobileNumber && driverName) || (flag == false && mobileNumber && driverName)) {
      if (true) {
        this.openVerifyModal();
      } else {
        AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.ValidMobile);
      }
    } else {
      AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.EnterDetails);
    }
  }

  getTrailerType(lang) {
    if (this.state.trailerTypeList && this.state.trailerTypeList[lang]) {
      const trailerDetails = this.state.trailerTypeList[lang].find(val => val.trailerId === this.state.trailerId);
      return trailerDetails && trailerDetails['trailerType'] || '';
    }
    return '';
  }

  handleConfirm() {
    const langType = LanguageUtils.getLangType(this.props);
    let index = this.props.location.state.index || 0;
    let flag = this.props.location.state.flag;
    const { input, mobileNumber, driverName, trailerNumber, trailerId, trailerType, liftGate, trailerSize, vistType } = this.state;
    if (
      (input.input1 &&
        // input.input2 &&
        input.input3 &&
        trailerType &&
        trailerSize &&
        liftGate &&
        this.props.location.state.reference_id) ||
      (vistType === "PL" && (this.props.masterData && this.props.masterData.delivery[index][0].trailer_details.trailer_number) && mobileNumber && driverName) ||
      (flag == false && input.input2 && input.input1)
    ) {
      const deliveryDetailObj = {
        driverName: input.input1,
        mobileNumber: input.input2,
        licensePlateNumber: this.state.licensePlateNumber,
        trailerNumber: input.input3 ? input.input3 : (this.props.masterData ? this.props.masterData.delivery[index][0].trailer_details.trailer_number : ''),
        trailerType: this.getTrailerType(langType) || "",
        trailerSize: this.state.trailerSize || "",
        liftGate: this.state.liftGate || "",
        reference_id: this.props.location.state.reference_id || ""
      };
      this.props.actions.deliveryData(deliveryDetailObj);
      let index = this.props.location.state.index || 0;
      this.setState({...this.state,openTermConditionPage:true,index:index,verifyModalIsOpen:false})
      // this.props.history.push({
      //   pathname: ROUTES.TERMSANDCONDITION,
      //   state: {
      //     index: index,
      //     didQueryReturnTrailerNumber: this.props.location.state.didQueryReturnTrailerNumber
      //   }
      // });
      // this.props.history.push(ROUTES.TERMSANDCONDITION);
    } else {
      AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.EnterDetails);
    }
  }

  showtrailerFields() {
    const langType = LanguageUtils.getLangType(this.props);
    const { vistType } = this.state;
    let indexValue = this.props.location.state.index || 0;
    let flag = this.props.location.state.flag;
    return (
      (flag && !(vistType === "PL" && this.props.masterData && this.props.masterData.delivery[indexValue][0].trailer_details.trailer_number)) &&
      <React.Fragment>
        <div className="form-group mb-20 mt-1">
          <input
            type="text"
            onFocus={() => this.setActiveInput("input3")}
            value={this.state.input["input3"] || ""}
            placeholder={LANGCONSTANT[langType].LABELS.TrailerNumber}
            className="form-control mb-10"
            defaultValue={this.state.trailerNumber}
            name="trailerNumber"
            onChange={event => this.handleChange(event)}
          />
        </div>

        <label className='mb-2'>{LANGCONSTANT[langType].LABELS.TrailerType}</label>
        <div className='d-flex flex-wrap check-box-wrapper'>
          {
            this.state.trailerTypeList && this.state.trailerTypeList[langType].map((type, index)=>{
              return(
                <div className="form-check" key={index}>
                  <input 
                    className="form-check-input" 
                    type="radio" 
                    name='trailerId'
                    id={`type-${type.trailerType}`}
                    checked={type.trailerId==this.state.trailerId}
                    onChange={(event) => this.handleDataChange(event)} 
                    value={type.trailerId}
                  />
                  <label className="form-check-label" for={`type-${type.trailerType}`}>
                    {type.trailerType}
                  </label>
                </div>
              )
            })
          }
        </div>

        <label className='mb-2 mt-2'>{LANGCONSTANT[langType].LABELS.TrailerSize}</label>
        <div className='d-flex flex-wrap check-box-wrapper'>
          {this.state.trailerSizeList.map((size, index)=>{
            return(
              <div className="form-check" key={index}>
                <input 
                  className="form-check-input" 
                  type="radio"
                  id={`size-${size.value}`}
                  value={size.value}
                  name="trailerSize"
                  onChange={event => this.handleDataChange(event)}
                  checked={size.value==this.state.trailerSize}
                />
                <label className="form-check-label" for={`size-${size.value}`}>
                  {size.value}
                </label>
              </div>
            )
          })}
        </div>

        <label className='mb-2 mt-2'>Lift Gate</label>
         <div className='d-flex flex-wrap check-box-right-wrapper'>
          <div className="form-check">
            <input 
              className="form-check-input" 
              type="radio" 
              name="lift-gate" 
              value="yes"
              id='yes'
              onChange={event => this.handleDataChange(event)}
            />
            <label className="form-check-label" for="yes">
              Yes
            </label>
          </div>
          <div className="form-check">
            <input 
              className="form-check-input" 
              type="radio" 
              id="no" 
              value="no"
              onChange={event => this.handleDataChange(event)}
              name="lift-gate" 
              defaultChecked
            />
            <label className="form-check-label" for="no">
             {LANGCONSTANT[langType].BUTTONS.No}
            </label>
          </div>      
        </div>
      </React.Fragment>
    )
  }

  // ------------------------------------------------------------------------------------

  handleChange(event) {
    let inputVal = event.target.value;

    let updatedInputObj = {
      ...this.state.input,
      [this.state.inputName]: inputVal
    };

    this.setState(
      {
        input: updatedInputObj
      },
      () => {
        // this.keyboardRef.keyboard.setInput(inputVal);
      }
    );
  }

  onChangeAll = inputObj => {
    this.setState({
      input: inputObj,
      driverName: inputObj.input1,
      mobileNumber: inputObj.input2,
      trailerNumber: inputObj.input3
    });
  };

  onKeyPress = button => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") this.handleShift();
    if (button === "{enter}") this.verifyMobileNumber();
  };

  handleShift = () => {
    let layoutName = this.state.layoutName;

    this.setState({
      layoutName: layoutName === "default" ? "shift" : "default"
    });
  };

  setActiveInput = inputName => {
    this.setState(
      {
        inputName: inputName
      },
      () => { }
    );
  };



  render() {
    const langType = LanguageUtils.getLangType(this.props);
    const { vistType } = this.state;
      return (
      <div>
        {/* <IdleTimerComponent /> */}
        {this.state.openTermConditionPage && (
          <TermsAndConditionsComponent 
            index={this.state.index}
            didQueryReturnTrailerNumber={this.props.location.state.didQueryReturnTrailerNumber}
            closeTermCondittionModal={()=>this.setState({...this.state,openTermConditionPage:false})}
          />
        )}
        <Header history={this.props.history} />
        <div className="body-container">
          <section>
            <div className="checkout-process-tabber">
              <div className="checkout-process-wrapper">
                <div className="checkout-process-container container-890 white-box delivery-detail">
                  <p className='form-title mb-20'>{LANGCONSTANT[langType].STRINGS.EnterBelowDetails}</p>
                  <form className="d-flex flex-wrap flex-column">
                    <div className="form-group mb-10">
                      <input
                        type="text"
                        onFocus={() => this.setActiveInput("input1")}
                        value={this.state.input["input1"] || ""}
                        defaultValue={this.state.driverName}
                        placeholder={LANGCONSTANT[langType].LABELS.DriverName}
                        className="form-control"
                        name="driverName"
                        onChange={event => this.handleChange(event)}
                      />
                    </div>
                    <div className="form-group mb-10 mt-1">
                      <input
                        type="text"
                        value={this.state.licensePlateNumber}
                        placeholder="License Plate Number"
                        className="form-control"
                        name="licensePlateNumber"
                        onChange={event => this.handleDataChange(event)}
                      />
                    </div>
                    {/* <div className="form-group mb-10 mt-1">
                      <input
                        type="tel"
                        onFocus={() => this.setActiveInput("input2")}
                        value={this.state.input["input2"] || ""}
                        placeholder={LANGCONSTANT[langType].LABELS.PhoneNumber}
                        maxLength="10"
                        minLength="10"
                        defaultValue={this.state.mobileNumber}
                        className="form-control"
                        name="mobileNumber"
                        onKeyPress={event => this.checkNumber(event)}
                        onChange={event => this.handleChange(event)}
                      />
                    </div> */}
                    {this.showtrailerFields()}
                    <NavButtons 
                      nextText={LANGCONSTANT[langType].BUTTONS.Confirm}
                      nextButtonAction={(e) => this.verifyMobileNumber(e)}
                    />
                  </form>
                  {/* <div className="keyboard d-flex">
                    <Keyboard
                      ref={r => (this.keyboardRef = r)}
                      inputName={this.state.inputName}
                      syncInstanceInputs={true}
                      display={{
                        "{bksp}": "backspace",
                        "{enter}": " enter",
                        "{space}": "space"
                      }}
                      layout={{
                        default: ["q w e r t y u i o p {bksp}", "a s d f g h j k l {enter}", "z x c v b n m", "{space}"]
                      }}
                      onChangeAll={inputObj => this.onChangeAll(inputObj)}
                      onKeyPress={button => this.onKeyPress(button)}
                    />
                    <div className="keyboard2-container">
                      <Keyboard
                        syncInstanceInputs={true}
                        inputName={this.state.inputName}
                        baseclassName={"keyboard2"}
                        layout={{
                          default: ["1 2 3", "4 5 6", "7 8 9", "0"]
                        }}
                        onChangeAll={inputObj => this.onChangeAll(inputObj)}
                        onKeyPress={button => this.onKeyPress(button)}
                      />
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </section>
        </div>
        <div>
          <Modal
            isOpen={this.state.verifyModalIsOpen}
            onAfterOpen={() => this.afterVerifyOpenModal()}
            onRequestClose={() => this.closeVerifyModal()}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="confirm-mobile-number"
          >
            <div className="modal pt-5 confirmation-modal-dialog">
              <div className="delivery-access-body">
                <h5 className="mt-2 mb-3" ref={subtitle => (this.subtitle = subtitle)}>
                  {LANGCONSTANT[langType].STRINGS.ConfirmNumber}
                </h5>
                <div className="form-group" style={{ textAlign: "center" }}>
                  {this.state.mobileNumber}
                  <p>{LANGCONSTANT[langType].STRINGS.SendNotification}</p>
                </div>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-disable mx-2 cancle-btn"
                    onClick={() => this.closeVerifyModal()}
                  >
                    {LANGCONSTANT[langType].BUTTONS.Cancel}
                  </button>
                  <button
                    className="btn btn-dark md-button mx-2"
                    onClick={() => this.handleConfirm()}
                  >
                    {LANGCONSTANT[langType].BUTTONS.Confirm}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

CheckinDeliveryDetail.propTypes = {
  actions: propTypes.object.isRequired
};

function mapStateToProps(state) {
  const data = state.data;
  return data;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(dataAction, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckinDeliveryDetail);

import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';
import PageProgressComponent from '../../common/PageProgressComponent';
import { connect } from 'react-redux';
import Header from '../../common/Header';
import * as LANGCONSTANT from '../../constants/language';
import IdleTimerComponent from '../../common/idleCheck/IdleTimerComponent';
import LanguageUtils from '../../utils/LanguageUtils';

class DropTrailerResultComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageNumber: 4,
            pageName: LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.CheckIn,
            pageNameList: [
                LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanGatePass,
                LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.AcceptTC,
                LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.Result
            ],
            DeliveryReturnNameList: [
                LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanGatePass,
                LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.EnterDetails,
                LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.Result
            ],
            totalPage: 4,
            showPage: true
        }
    }

    componentWillMount() {
        this.checkPreviousPage();
    }

    async checkPreviousPage() {
        const langType = LanguageUtils.getLangType(this.props);
        if (!!this.props.location.state) {
            if (this.props.location.state.detail === ROUTES.TERMSANDCONDITION) {
                await this.setState({
                    // dropTrailerData: true,
                    showPage: true,
                    pageName: LANGCONSTANT[langType].LABELS.DropTrailers,
                    pageNameList: [
                        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanGatePass,
                        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.AcceptTC,
                        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.Result
                    ],
                    totalPage: 3,
                    pageNumber: 3
                });
            } else if (this.props.location.state.detail === ROUTES.DELIVERYANDRETURNENTERDETAIL) {
                await this.setState({
                    // dropTrailerData: true,
                    showPage: false,
                    pageName: LANGCONSTANT[langType].LABELS.DeliveryReturn,
                    pageNameList: [
                        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanGatePass,
                        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.EnterDetails,
                        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.Result
                    ],
                    totalPage: 3,
                    pageNumber: 3
                });
            }
        } else {
            this.setState({
                checkin: true,
                pageName: LANGCONSTANT[langType].LABELS.CheckIn
            });
        }
    }

    backToHome() {
        this.props.history.push(ROUTES.LANGUAGE);
    }

    render() {
        const langType = LanguageUtils.getLangType(this.props);
        return (
            <div>
                {/* <IdleTimerComponent /> */}
                <Header history={this.props.history} />
                <div>
                    <div className="body-container">
                        <section>
                            <div className="checkout-process-tabber">
                                <div className="checkout-process-wrapper">
                                    <div className="checkout-process-container terms-listing">
                                        <div className="checkout-process-container container-890 white-box gate-allotted text-center">
                                            <div className="tick"><img src={require("../../assets/images/tick.png")} alt="" width="88px;" /></div>
                                            <h4>{this.state.showPage ? this.props.location.state.dockdoor : this.props.deliveryReturnData.dockdoor}</h4>
                                            <h6>{this.state.showPage ? this.props.location.state.shipment_flag : this.props.deliveryReturnData.shipment_flag}</h6>
                                            {/* <p>{LANGCONSTANT[langType].STRINGS.GatePassDetails}</p> */}
                                            <input type='button' className="btn btn-disable" onClick={() => this.backToHome()} value={LANGCONSTANT[langType].BUTTONS.BackToHome} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div className="progress-bar-bottom">
                            <PageProgressComponent
                                pageName={this.state.showPage ?
                                    LANGCONSTANT[langType].LABELS.DropTrailers :
                                    LANGCONSTANT[langType].LABELS.DeliveryReturn
                                }
                                pageNumber={this.state.pageNumber} totalPage={this.state.totalPage}
                                pageNameList={this.state.showPage ?
                                    ([
                                        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanGatePass,
                                        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.AcceptTC,
                                        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.Result
                                    ]) :
                                    ([
                                        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanGatePass,
                                        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.EnterDetails,
                                        LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.Result
                                    ])
                                } />
                        </div>
                    </div>
                </div>
                
            </div>
        );
    }
}

function mapStateToProps(state) {
    const data = state.data;
    return data;
}

export default connect(
    mapStateToProps
)(DropTrailerResultComponent);
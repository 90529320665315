import React, { Component } from 'react';
import { CONFIG } from '../../configuration/config';
import { CallApi } from '../../services/service';
import CheckinComponent from '../Checkin/CheckinComponent';
import CheckoutComponent from '../Checkout/CheckoutComponent';
import { spinnerName } from '../../constants/data';
import { spinnerService } from '../../services/spinner.service';
import * as LANGCONSTANT from '../../constants/language';
import { connect } from 'react-redux';
import Header from '../../common/Header';
import LanguageUtils from '../../utils/LanguageUtils';

class ConfigureComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectOption: false,
      facilityArray: [],
      checkinLaneArray: [],
      checkOutLaneArray: [],
      result: []
    };
  }

  componentWillMount() {
    this.callFacility();
  }

  callFacility() {
    const language = LanguageUtils.getLangType(this.props);
    spinnerService.show(spinnerName.LOGIN_SPINNER);
    CallApi('GET', CONFIG.getFacilities, {}, {}, language, "getFacilities", this);
  }

  getFacilities = (response) => {
    if (response.success) {
      this.setState({ result: response.result });
    }
  }

  ChangeSelectionToCheckIn() {
    this.setState({ selectOption: false });
    this.selectionComponent();
  }

  ChangeSelectionToCheckOut() {
    this.setState({ selectOption: true });
    this.selectionComponent();
  }

  selectionComponent() {
    if (true) {
      return (
        <CheckinComponent
          history={this.props.history}
          data={this.state.result}
        />
      );
    } else {
      return (
        <CheckoutComponent
          history={this.props.history}
          data={this.state.result}
        />
      );
    }
    // }
  }

  render() {
    const langType = LanguageUtils.getLangType(this.props);
    return (
      <div>
        <Header history={this.props.history} showHome={true} />
        <div className="body-container">
          <section>
            <div className="container-586">
              <div className="tabber">
                <h3 className="text-center">
                  {LANGCONSTANT[langType].LABELS.Configure}{' '}
                  <strong>{LANGCONSTANT[langType].LABELS.Device}</strong>
                </h3>
                <div className="tabber-list">
                  <ul>
                    <li className={!this.state.selectOption ? 'active' : ''}>
                      <a
                        href="javaScript:void(0)"
                        onClick={() => this.ChangeSelectionToCheckIn()}
                      >
                        {LANGCONSTANT[langType].LABELS.CheckIn}
                      </a>
                    </li>
                    <li className={this.state.selectOption ? 'active' : ''}>
                      <a
                        href="javaScript:void(0)"
                        onClick={() => this.ChangeSelectionToCheckOut()}
                      >
                        {LANGCONSTANT[langType].LABELS.CheckOut}
                      </a>
                    </li>
                  </ul>
                  {this.selectionComponent()}
                </div>
              </div>
            </div>
            <div />
          </section>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const data = state.data;
  return data;
}

export default connect(mapStateToProps)(ConfigureComponent);

import React, { Component } from 'react';
import { CONFIG } from '../../configuration/config';
import { CallApi } from '../../services/service';
import * as ROUTES from '../../constants/routes';
import PageProgressComponent from '../../common/PageProgressComponent';
import { connect } from 'react-redux';
import * as dataAction from '../../redux/actions/checkinAction';
import propTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { spinnerName } from '../../constants/data';
import { spinnerService } from '../../services/spinner.service';
import * as LANGCONSTANT from '../../constants/language';
import LanguageUtils from '../../utils/LanguageUtils';
import IdleTimerComponent from '../../common/idleCheck/IdleTimerComponent';
import Header from '../../common/Header';
import AlertModal from "../../common/modals/AlertModal";
import Modal from 'react-modal';
import checkMark from '../../assets/images/check-mark.svg'
import {getData} from '../../containers/storageHandler'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

class TermsAndConditionsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      check: true,
      dropTrailerData: false,
      checkin: true,
      isTermConditionModal:true,
      modalIsOpen:false,
      pageNumber: 4,
      pageName: { pageName: LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.CheckIn },
      pageNameList: [
        LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.ScanGatePass,
        LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.SelectAppointment,
        LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.EnterDetails,
        LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.AcceptTC
      ],
      dropTrailerList: [
        LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.ScanGatePass,
        LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.AcceptTC,
        LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.Result
      ],
      totalPage: 4,
      dockdoor: '',
      shipment_flag: ''
    };
  }

  componentWillMount() {
    this.checkPreviousPage();
  }

  handleChange(event) {
    if (!this.state.check) {
      this.setState({
        check: true,
        confirm: false
      });
    } else {
      this.setState({
        check: false
      });
    }
  }

  back() {
    // this.props.history.goBack();
    this.props.closeTermCondittionModal()
  }

  handleConfirm() {
   
    const index = this.props.index;

    const mobileNumber = getData('userPhoneNumber');

    const precheckObj = {
      reference_id: this.props.deliveryData ? this.props.deliveryData.reference_id : '',
      org_id: this.props.masterData ? this.props.masterData.org_id : '',
      customer_po: this.props.masterData ? this.props.masterData.delivery[index][0].customer_po : '',
      delivery_id: this.props.masterData ? this.props.masterData.delivery[index][0].id : '',
      trip_id: this.props.masterData ? this.props.masterData.delivery[index][0].trip_id : '',
      visit_type: this.props.masterData ? this.props.masterData.delivery[index][0].visit_type : '',
      language: langType === 'ESP' ? 'es' : "en",
      lane_no: this.props.checkinData ? (this.props.checkinData.laneNo ? parseInt(this.props.checkinData.laneNo, 10) : '') : '',
      carrier_scac: this.props.masterData ? this.props.masterData.delivery[index][0].carrier_scac : '',
      driver_name: this.props.deliveryData ? this.props.deliveryData.driverName : '',
      phone_no: mobileNumber,
      trailer_length: this.props.deliveryData ? (this.props.deliveryData.trailerSize ? parseInt(this.props.deliveryData.trailerSize, 10) : '') : '',
      trailer_Type: this.props.deliveryData ? this.props.deliveryData.trailerType : '',
      trailer_number: this.props.deliveryData ? this.props.deliveryData.trailerNumber : '',
      customer_name: this.props.masterData ? this.props.masterData.delivery[index][0].customer_name : '',
      address: this.props.masterData ? this.props.masterData.delivery[index][0].address : '',
      did_query_return_trailer_number: false,
      carrier_name: this.props.masterData ? this.props.masterData.delivery[0][0].carrier_name : "",
      licensePlateNumber: this.props.deliveryData.licensePlateNumber
    };

    const langType = LanguageUtils.getLangType(this.props);

    CallApi('POST', CONFIG.postPrecheckData, {}, precheckObj, langType, "returnPrecheckSubmit", this);
  }

  returnPrecheckSubmit = response => {
    if (response.result.status) {
      this.setState({
        confirm: true,
      });
       this.setState({...this.state,isTermConditionModal:false,modalIsOpen:true})
    } else {
      // this.props.closeTermCondittionModal()
       this.setState({...this.state,isTermConditionModal:false})
      AlertModal.showAlert(response.result.shipment_flag);
    }
  };

  returnOrderCheckin = response => {
    if (response.result.checkin_status) {
      this.setState({
        confirm: true,
        dockdoor: response.result.dockdoor,
        shipment_flag: response.result.shipment_flag
      });
       this.setState({...this.state,isTermConditionModal:false,modalIsOpen:true})
    } else {
      // this.props.closeTermCondittionModal()
       this.setState({...this.state,isTermConditionModal:false})
      AlertModal.showAlert(response.result.shipment_flag);
    }
  };

  returnDropEmpty = response => {
    if (response.success === true) {
      if (response.result.checkin_status === true) {
        spinnerService.hide(spinnerName.LOGIN_SPINNER);
        this.props.history.push({
          pathname: ROUTES.DROPTRAILERRESULT,
          state: {
            detail: ROUTES.TERMSANDCONDITION,
            dockdoor: response.result.dockdoor,
            shipment_flag: response.result.shipment_flag
          }
        });
      } else {
        AlertModal.showAlert(response.result.shipment_flag);
      }
    } else {
      AlertModal.showAlert(response.message);
    }
  };

  returnCheckinAndDropEmpty = response => {
    if (response.success === true) {
      if (response.result.checkin_status === true) {
        this.props.history.push({
          pathname: ROUTES.DROPTRAILERRESULT,
          state: {
            detail: ROUTES.TERMSANDCONDITION,
            dockdoor: response.result.dockdoor,
            shipment_flag: response.result.shipment_flag
          }
        });
      } else {
        AlertModal.showAlert(response.result.shipment_flag);
      }
    } else {
      AlertModal.showAlert(response.message);
    }
  };

  async checkPreviousPage() {
    const langType = LanguageUtils.getLangType(this.props);
    if (this.props !== undefined) {
      if (this.props.detail === '/droptrailers') {
        await this.setState({
          dropTrailerData: true,
          pageName: LANGCONSTANT[langType].LABELS.DropTrailers,
          pageNameList: [
            LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.ScanGatePass,
            LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.AcceptTC,
            LANGCONSTANT[this.props.languageData ? this.props.languageData.langType : 'ENG'].LABELS.Result
          ],
          totalPage: 3,
          pageNumber: 2
        });
      }
    } else {
      this.setState({ checkin: true, pageName: LANGCONSTANT[langType].LABELS.CheckIn });
    }
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
    this.props.history.push(ROUTES.CHECKINGATEPASS);
  }

  backToHome() {
    this.props.history.push(ROUTES.LANGUAGE);
  }

  render() {
    const langType = LanguageUtils.getLangType(this.props);
    if (!this.state.confirm) {
      return (
        <div>
          {/* <IdleTimerComponent /> */}
          <Header history={this.state.confirm} />
          <Modal 
            isOpen={!this.state.confirm || this.state.isTermConditionModal}
            onRequestClose={()=>{this.back();this.setState({...this.state,isTermConditionModal:false})}}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="term_condition-modal"
          >
          <div>
            <section>
              <div className="checkout-process-tabber">
                <div className="checkout-process-wrapper">
                  <div className="checkout-process-container terms-listing">
                    <div className="white-box container-890 terms-condition-box">
                      <h4>{LANGCONSTANT[langType].LABELS.TermsAndConditions}</h4>
                      <ul>
                        <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsOne}</li>
                        <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsTwo}</li>
                        <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsThree}</li>
                        <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsFour}</li>
                        <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsFive}</li>
                        <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsSix}</li>
                        <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsSeven}</li>
                        <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsEight}</li>
                        <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsNine}</li>
                        <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsTen}</li>
                        <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsEleven}</li>
                        <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsTweleve}</li>
                      </ul>
                   
                      <form>
                        {/* <div className="form-group">
                          <label>
                            <input
                              type="checkbox"
                              checked={this.state.check}
                              onChange={event => this.handleChange(event)}
                            />{' '}
                            <span>
                              {LANGCONSTANT[langType].STRINGS.TermsCondition}
                            </span>
                          </label>
                        </div> */}
                        <div className="text-center btn-wrapper mt-4">
                          <input
                            type="button"
                            className="btn btn-disable cancle-btn mx-2"
                            onClick={() => this.back()}
                            // value={LANGCONSTANT[langType].BUTTONS.Back}
                            value='Decline'
                          />
                          <input
                            type="button"
                            className="btn btn-dark btn-submit mx-2"
                            onClick={() => this.handleConfirm()}
                            // value={LANGCONSTANT[langType].BUTTONS.Confirm}
                            value='Accept'
                          />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          </Modal>
        </div>
      );
    } 
    else if (this.state.confirm) {
      console.log(this.state);
      // const langType = LanguageUtils.getLangType(this.props);
      // const smsObj = {
      //   userPhoneNumber: '17143636861',
      //   message: String(this.state.dockdoor)
      // }
      // CallApi('POST', CONFIG.sendSMSInfo, {}, smsObj, langType, "", this);
      return (
        <div>
          {/* <IdleTimerComponent/> */}
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={() => this.closeModal()}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="success-modal">
            <div className="align-items-center d-flex justify-content-center">
              <div>
                <div className="checkout-process-wrapper">
                  <div className="checkout-process-container terms-listing">
                    <div className="checkout-process-container container-890 white-box gate-allotted text-center">
                        <div className='text-center content'>
                          <img src={checkMark}/>
                          <h3>Express Check In request has been submitted, we look forward to your arrival.</h3>
                        </div>
                      <h4>{this.state.shipment_flag}</h4>
                      {/* <input
                        type="button"
                        className="btn btn-disable"
                        onClick={() => this.backToHome()}
                        value={LANGCONSTANT[langType].BUTTONS.BackToHome}
                      /> */}
                    </div>
                  </div>
                </div>
                <center>
                  <button
                    className="btn btn-dark md-button mx-2"
                    onClick={() => this.closeModal()}
                  >
                    {LANGCONSTANT[langType].BUTTONS.Ok}
                  </button>
                </center>
              </div>
            </div>
          </Modal>
        </div>
      );
    }
  }
}
TermsAndConditionsComponent.propTypes = {
  actions: propTypes.object.isRequired
};

function mapStateToProps(state) {
  const data = state.data;
  return data;
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(dataAction, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsAndConditionsComponent);

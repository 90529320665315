import React, { Component } from "react";
import { connect } from "react-redux";
import * as ROUTES from "../../constants/routes";
import PageProgressComponent from "../../common/PageProgressComponent";
import Header from "../../common/Header";
import * as LANGCONSTANT from "../../constants/language";
import LanguageUtils from "../../utils/LanguageUtils";
import IdleTimerComponent from "../../common/idleCheck/IdleTimerComponent";
import Modal from "react-modal";
import AlertModal from "../../common/modals/AlertModal";
import NavButtons from "../../common/NavButtons";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

class CheckinAppointmentComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointmentData: [],
      pageNumber: 2,
      modalIsOpen: false,
      noTrailerModalIsOpen: false,
      index: 0
    };
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  async componentWillMount() {
    await this.setData();
  }

  openModal(index) {
    this.setState({
      modalIsOpen: true,
      index: index
    });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = "#6495ed";
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  openNoTrailerModal(index) {
    this.setState({
      noTrailerModalIsOpen: true,
      index: index
    });
  }

  closeNoTrailerModal() {
    this.setState({ noTrailerModalIsOpen: false });
  }

  async setData() {
    await this.setState({
      appointmentData: this.props.masterData ? this.props.masterData.delivery : []
    });
    console.log('data set');
    console.log(this.state);
  }

  handleData(index) {
    if (this.state.appointmentData[index][0].visit_type === "PL") {
      if (
        // this.state.appointmentData[0][0].trailer_details.trailer_status == null ||
        !!this.state.appointmentData[index][0].trailer_details.trailer_number
      ) {
        // open confirmation modal Do you want to drop an empty trailer
        this.openModal(index);
      } else {
        this.openNoTrailerModal(index);
      }
    } else {
      this.props.history.push({
        pathname: ROUTES.DELIVERYDETAILS,
        state: {
          detail: ROUTES.APPOINTMENTDETAILS,
          reference_id: this.props.location.state.reference_id,
          index: 0,
          flag: true,
          didQueryReturnTrailerNumber: this.props.location.state.didQueryReturnTrailerNumber
        }
      });
    }
  }

  handleModalNoTrailer() {
    this.closeNoTrailerModal();
    // AlertModal.showAlert('Please contact shipping office.');
    // setTimeout(() => {
    //   AlertModal.hideAlert();
    //   this.props.history.push(ROUTES.CHECKINGATEPASS);
    // }, 10000);
    this.props.history.push({
      pathname: ROUTES.DELIVERYDETAILS,
      state: {
        detail: ROUTES.APPOINTMENTDETAILS,
        reference_id: this.props.location.state.reference_id,
        index: this.state.index,
        flag: false,
        didQueryReturnTrailerNumber: this.props.location.state.didQueryReturnTrailerNumber
      }
    });
  }

  handleModalYesTrailer() {
    this.closeNoTrailerModal();
    this.props.history.push({
      pathname: ROUTES.DELIVERYDETAILS,
      state: {
        detail: ROUTES.APPOINTMENTDETAILS,
        reference_id: this.props.location.state.reference_id,
        index: this.state.index,
        flag: true,
        didQueryReturnTrailerNumber: this.props.location.state.didQueryReturnTrailerNumber
      }
    });
  }

  handelModalNo() {
    this.props.history.push({
      pathname: ROUTES.DELIVERYDETAILS,
      state: {
        detail: ROUTES.APPOINTMENTDETAILS,
        reference_id: this.props.location.state.reference_id,
        index: this.state.index,
        flag: true,
        didQueryReturnTrailerNumber: this.props.location.state.didQueryReturnTrailerNumber
      }
    });
  }

  handleModalSubmit() {
    this.props.history.push({
      pathname: ROUTES.DROPTRAILERS,
      state: {
        checkFlow: true,
        reference_id: this.props.location.state.reference_id,
        index: this.state.index,
        didQueryReturnTrailerNumber: this.props.location.state.didQueryReturnTrailerNumber
      }
    });
  }

  render() {
    const langType = LanguageUtils.getLangType(this.props);
    return (
      <div>
        {/* <IdleTimerComponent /> */}
        <Header history={this.props.history} />
        <div className="body-container">
          <section className="appointment-wrapper">
            <div className="checkout-process-tabber">
              <div className="checkout-process-wrapper">
                <div className="checkout-process-container container-890 checkin-appointment-container">
                  {/* <h3 className="form-title">
                    {LANGCONSTANT[langType].LABELS.SelectAnAppointment}
                  </h3> */}
                  <h3 className="form-title mb-0">
                    Appointment 
                  </h3>
                  <span class="form-subtitle">Please start with the process</span>
                  {this.state.appointmentData.map((deliveryData, index) => {
                    return (
                      <div className="confirmation-detail" key={'deliveryData[0].id'}>
                        <p className="appointment-detail-h">{LANGCONSTANT[langType].LABELS.AppointmentDetails}</p>
                        <div className="d-flex appointment-wrapper-box">
                          <div className="confirmation-trailer-detail">
                            <ul>
                              <li>
                                <label>{LANGCONSTANT[langType].LABELS.DestinationLocation}</label>
                                <p>{deliveryData[0].address}</p>
                              </li>
                              <li>
                                <label>{LANGCONSTANT[langType].LABELS.AppointmentDate}</label>
                                <p>{deliveryData[0].pickup_app_time}</p>
                              </li>
                              <li>
                                <label>{LANGCONSTANT[langType].LABELS.DeliveryNumber}</label>
                                <p>{deliveryData[0].id}</p>
                              </li>
                              <li>
                                <label>{LANGCONSTANT[langType].LABELS.AppointmentNumber}</label>
                                <p>{deliveryData[0].trip_id}</p>
                              </li>
                              <li>
                                <label>{LANGCONSTANT[langType].LABELS.Status}</label>
                                <p>{deliveryData[0].trailer_details.trailer_status}</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                          <NavButtons 
                            nextButtonAction={() => this.handleData(0)}
                          />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        </div>
        <div>
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Example Modal">
            <div className="modal confirmation-modal-dialog">
              <div className="delivery-access-body">
                <h5 ref={subtitle => (this.subtitle = subtitle)}>{LANGCONSTANT[langType].STRINGS.DropEmptyTrailer}</h5>
                <div className="d-flex">
                  <button className="btn btn-disable" onClick={() => this.handelModalNo()}>
                    {LANGCONSTANT[langType].BUTTONS.No}
                  </button>
                  <button className="btn btn-submit" onClick={() => this.handleModalSubmit()}>
                    {LANGCONSTANT[langType].BUTTONS.Yes}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
        <div>
          <Modal
            isOpen={this.state.noTrailerModalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeNoTrailerModal}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Example Modal">
            <div className="modal confirmation-modal-dialog">
              <div className="delivery-access-body">
                <h5 ref={subtitle => (this.subtitle = subtitle)}>{LANGCONSTANT[langType].STRINGS.DoHaveTrailer}</h5>
                <div className="d-flex">
                  <button className="btn btn-disable" onClick={() => this.handleModalNoTrailer()}>
                    {LANGCONSTANT[langType].BUTTONS.No}
                  </button>
                  <button className="btn btn-submit" onClick={() => this.handleModalYesTrailer()}>
                    {LANGCONSTANT[langType].BUTTONS.Yes}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const data = state.data;
  return data;
}

export default connect(mapStateToProps)(CheckinAppointmentComponent);

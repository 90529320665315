import React from 'react'
import { useHistory } from "react-router-dom";
import { setData, getData } from '../containers/storageHandler';
import rightArrow from '../assets/images/arrowiosforward.svg'
import leftArrow from '../assets/images/arrowiosback.svg'

const NavButtons = (props) => {
  const history = useHistory();
  // const pageName = getData('pageName').pageName;
  
  return (
    <div className="navigation-buttons">
      {/* {pageName === 'checkin' ? <> */}
        <button className="btn btn-dark md-button" onClick={()=>history.goBack()}>
          <span className="btn-text"><img style={{height: '2rem'}} src={leftArrow} /> Previous</span>
        </button>
        <button className="btn btn-dark md-button" onClick={props.nextButtonAction}>
          <span className="btn-text">{props.nextText ? props.nextText : 'Next'} <img style={{height: '2rem'}} src={rightArrow} /></span>
        </button>
    </div>
  )
}

export default NavButtons;
import React, { Component } from "react";
import { CallApi } from "../../services/service";
import { CONFIG } from "../../configuration/config";
import * as ROUTES from "../../constants/routes";
import { connect } from "react-redux";
import * as checkoutAction from "../../redux/actions/checkoutAction";
import * as guardAction from "../../redux/actions/guardAction";
import * as dataAction from "../../redux/actions/checkoutShipmentData";
import * as pageAction from "../../redux/actions/pageDataAction";
import propTypes from "prop-types";
import PageProgressComponent from "../../common/PageProgressComponent";
import { spinnerName } from "../../constants/data";
import { spinnerService } from "../../services/spinner.service";
import * as LANGCONSTANT from "../../constants/language";
import LanguageUtils from "../../utils/LanguageUtils";
import Header from "../../common/Header";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import AlertModal from "../../common/modals/AlertModal";
import { setData, getData } from "../../containers/storageHandler";
import Modal from "react-modal";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};

class CheckoutGatePassComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trailerNumber: "",
            orderNumber: "",
            lookupType: "delivery",
            configurationData: getData("configureData"),
            facilityId: getData("configureData").facilityId,
            laneNo: getData("configureData").laneNo,
            skipInbound: getData("configureData").skipInbound,
            modalIsOpen: false,
            pageNumber: 2,
            layoutName: "default",
            inputName: "input1",
            index: 0,
            input: {},
            pageNameList: [
                LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ExitGate,
                LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanGatePass,
                LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ConfirmDetails,
                LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanSeal,
                LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.Sign,
            ],
        };
        const pageNameobj = {
            pageName: "checkout",
        };
        setData("pageName", pageNameobj);
        this.props.dispatch(pageAction.pageData(pageNameobj));
        const dataObj = {
            guard: true,
        };
        this.props.dispatch(guardAction.guardData(dataObj));
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = "#6495ed";
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    handleChange(event) {
        let inputVal = event.target.value;

        let updatedInputObj = {
            ...this.state.input,
            [this.state.inputName]: inputVal,
        };

        this.setState(
            {
                input: updatedInputObj,
            },
            () => {
                this.keyboardRef.keyboard.setInput(inputVal);
            }
        );
    }

    handleSubmit(event) {
        if (event !== "{enter}") {
            event.preventDefault();
        }
        this.props.dispatch(checkoutAction.checkoutData(getData("configureData")));
        const dataObj = {
            guard: true,
            trailerNumber: this.state.trailerNumber,
        };
        this.props.dispatch(guardAction.guardData(dataObj));

        const langType = LanguageUtils.getLangType(this.props);

        //Added keys to shipmentCheckoutObj
        // purchase_order_number
        // delivery_number

        const shipmentCheckoutObj = {
            reference_id: this.state.trailerNumber,
            purchase_order_number: this.state.lookupType === "purchaseOrder" ? this.state.orderNumber : "",
            delivery_number: this.state.lookupType === "delivery" ? this.state.orderNumber : "",
            language: this.props.languageData ? this.props.languageData.language : "en",
            org_id: this.state.facilityId,
            customer_load: "Yes",
        };
        if (this.state.trailerNumber && this.state.orderNumber) {
            spinnerService.show(spinnerName.LOGIN_SPINNER);
            CallApi("POST", CONFIG.queryCheckout, {}, shipmentCheckoutObj, langType, "returnQueryCheckout", this);
        } else {
            AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.EnterDetails);
        }
    }

    handleLookupTypeChange = (event) => {
        this.setState({
            lookupType: event.target.value,
        });
    };

    returnQueryCheckout = (response) => {
        if (response.success === true) {
            setData("isBOL", "Yes");
            this.props.dispatch(dataAction.checkoutShipmentData(response.result));
            this.props.history.push(ROUTES.CONFIRMTRAILERDETAILS);
        }
    };

    internalAccess() {
        // this.openInternalAccessModal();
        this.props.history.push(ROUTES.INTERNALACCESS);
    }

    unloadedCheckout() {
        setData("isBOL", "No");
        this.props.history.push(ROUTES.EXITINTERMEDIATE);
    }

    // ---------------------------------------------------------------------------------

    onChangeAll = (inputObj) => {
        this.setState({
            input: inputObj,
            trailerNumber: inputObj.input1,
            orderNumber: inputObj.input2,
        });
    };

    onKeyPress = (button) => {
        /**
         * If you want to handle the shift and caps lock buttons
         */
        if (button === "{shift}" || button === "{lock}") this.handleShift();
        if (button === "{enter}") this.handleSubmit(button);
    };

    handleShift = () => {
        let layoutName = this.state.layoutName;

        this.setState({
            layoutName: layoutName === "default" ? "shift" : "default",
        });
    };

    setActiveInput = (inputName) => {
        this.setState(
            {
                inputName: inputName,
            },
            () => {}
        );
    };

    render() {
        const langType = LanguageUtils.getLangType(this.props);
        return (
            <div>
                {/* <IdleTimerComponent /> */}
                <Header history={this.props.history} />
                <div className='body-container keyboard-container'>
                    <div className='gate-label'>{LANGCONSTANT[langType].LABELS.GatePassHeading}</div>
                    <section>
                        <div className='checkout-process-tabber'>
                            <div className='checkout-process-wrapper'>
                                <div className='checkout-process-container white-box container-890'>
                                    <div>
                                        <h4>{LANGCONSTANT[langType].LABELS.DeliveryDetails}</h4>
                                    </div>
                                    <form>
                                        <div className='d-flex justify-content-around'>
                                            <div className='form-group'>
                                                <input
                                                    type='tel'
                                                    onFocus={() => this.setActiveInput("input1")}
                                                    value={this.state.input["input1"] || ""}
                                                    className='form-control'
                                                    name='trailerNumber'
                                                    placeholder={LANGCONSTANT[langType].STRINGS.TrailerNumber}
                                                    // onChange={(event) =>
                                                    //     this.handleChange(event)
                                                    // }
                                                    autoFocus={true}
                                                />
                                            </div>

                                            <div className='form-group'>
                                                <input
                                                    type='tel'
                                                    name='deliveryOrPONumber'
                                                    className='form-control'
                                                    value={this.state.input["input2"] || ""}
                                                    placeholder={
                                                        this.state.lookupType === "delivery"
                                                            ? LANGCONSTANT[langType].STRINGS.DeliveryEnter
                                                            : LANGCONSTANT[langType].STRINGS.PurchaseOrderEnter
                                                    }
                                                    onFocus={() => this.setActiveInput("input2")}
                                                    onChange={(event) => this.handleChange(event)}
                                                />
                                                <div className='d-flex justify-content-between'>
                                                    <strong>Lookup Type: </strong>

                                                    <label className='radio-group'>
                                                        <input
                                                            type='radio'
                                                            value='delivery'
                                                            checked={this.state.lookupType === "delivery"}
                                                            name='lookupType'
                                                            onClick={(event) => this.handleLookupTypeChange(event)}
                                                        />
                                                        Delivery
                                                    </label>
                                                    <label className='radio-group'>
                                                        <input
                                                            type='radio'
                                                            value='purchaseOrder'
                                                            name='lookupType'
                                                            checked={this.state.lookupType === "purchaseOrder"}
                                                            onClick={(event) => this.handleLookupTypeChange(event)}
                                                        />
                                                        Purchase Order
                                                    </label>
                                                </div> 
                                            </div>

                                        </div>
                                        <div className='d-flex justify-content-end align-items-end'>
                                            {/* <button type='button' className='btn btn-disable'>Internal Access</button> */}
                                            <input
                                                type='submit'
                                                className='btn btn-submit'
                                                value={LANGCONSTANT[langType].BUTTONS.Submit}
                                                onClick={(ev) => this.handleSubmit(ev)}
                                            />
                                            <a href='javaScript:void(0)' onClick={() => this.openModal()}>
                                                <u>{LANGCONSTANT[langType].LABELS.TermsAndConditions}</u>
                                            </a>
                                        </div>
                                    </form>
                                    <div className='keyboard d-flex'>
                                        <Keyboard
                                            ref={(r) => (this.keyboardRef = r)}
                                            inputName={this.state.inputName}
                                            syncInstanceInputs={true}
                                            display={{
                                                "{bksp}": "backspace",
                                                "{enter}": " enter",
                                                "{space}": "space",
                                            }}
                                            layout={{
                                                default: [
                                                    "q w e r t y u i o p {bksp}",
                                                    "a s d f g h j k l {enter}",
                                                    "z x c v b n m",
                                                    "{space}",
                                                ],
                                            }}
                                            onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
                                            onKeyPress={(button) => this.onKeyPress(button)}
                                        />
                                        <div className='keyboard2-container'>
                                            <Keyboard
                                                syncInstanceInputs={true}
                                                inputName={this.state.inputName}
                                                baseClass={"keyboard2"}
                                                layout={{
                                                    default: ["1 2 3", "4 5 6", "7 8 9", "0"],
                                                }}
                                                onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
                                                onKeyPress={(button) => this.onKeyPress(button)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <div className='d-flex justify-content-center btn-wrapper'>
                        <button type='button' className='btn btn-delivery' onClick={() => this.unloadedCheckout()}>
                            {LANGCONSTANT[langType].LABELS.UnloadedCheckout}
                        </button>
                        <button type='button' className='btn btn-delivery' onClick={() => this.internalAccess()}>
                            {LANGCONSTANT[langType].LABELS.InternalAccess}
                        </button>
                    </div>
                    <div className='progress-bar-bottom'>
                        <PageProgressComponent
                            pageName={LANGCONSTANT[langType].LABELS.CheckOut}
                            pageNumber={this.state.pageNumber}
                            totalPage={5}
                            pageNameList={[
                                LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ExitGate,
                                LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanGatePass,
                                LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ConfirmDetails,
                                LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanSeal,
                                LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.Sign,
                            ]}
                        />
                    </div>
                </div>
                <div>
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onAfterOpen={() => this.afterOpenModal()}
                        onRequestClose={() => this.closeModal()}
                        style={customStyles}
                        ariaHideApp={false}
                        contentLabel='Example Modal'
                    >
                        <div className='modal'>
                            <h2 ref={(subtitle) => (this.subtitle = subtitle)}>
                                {LANGCONSTANT[langType].LABELS.TermsAndConditions}
                            </h2>
                            <ol>
                                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsOne}</li>
                                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsTwo}</li>
                                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsThree}</li>
                                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsFour}</li>
                                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsFive}</li>
                                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsSix}</li>
                                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsSeven}</li>
                                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsEight}</li>
                                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsNine}</li>
                                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsTen}</li>
                                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsEleven}</li>
                                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsTweleve}</li>
                            </ol>
                            <center>
                                <button onClick={() => this.closeModal()}>{LANGCONSTANT[langType].BUTTONS.Ok}</button>
                            </center>
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }
}

CheckoutGatePassComponent.propTypes = {
    dispatch: propTypes.func.isRequired,
};

function mapStateToProps(state) {
    const data = state.data;
    return data;
}

export default connect(mapStateToProps)(CheckoutGatePassComponent);

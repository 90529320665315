import React, { Component } from 'react';
import { CallApi } from '../../services/service';
import { CONFIG } from '../../configuration/config';
import PageProgressComponent from '../../common/PageProgressComponent';
import { connect } from 'react-redux';
import * as ROUTES from '../../constants/routes';
import * as guardAction from '../../redux/actions/guardAction';
import * as checkinAction from '../../redux/actions/checkinAction';
import * as dataAction from '../../redux/actions/masterDataAction';
import * as pageAction from '../../redux/actions/pageDataAction';
import propTypes from 'prop-types';
import { spinnerName } from '../../constants/data';
import * as LANGCONSTANT from '../../constants/language';
import { spinnerService } from '../../services/spinner.service';
import LanguageUtils from '../../utils/LanguageUtils';
import Header from '../../common/Header';
import Modal from 'react-modal';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import { setData, getData } from '../../containers/storageHandler';
import AlertModal from "../../common/modals/AlertModal";
import CheckinAppointmentComponent  from '../checkinSelection/CheckinAppointmentComponent'
import rightArrow from '../../assets/images/arrowiosforward.svg'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

class CheckinGatePassComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pickupNumber: '',
      accessCode: '',
      facilityId: getData('configureData').facilityId,
      laneNo: getData('configureData').laneNo,
      pageName: 'checkinPage',
      modalIsOpen: false,
      internalAccessModalIsOpen: false,
      pageNumber: 1,
      modalMessage: '',
      device_id: '',
      layoutName: "default",
      inputName: "input1",
      index: 0,
      input: {},
      mobile: false
    };
    const pageNameobj = {
      pageName: 'checkin'
    };
    setData('pageName', pageNameobj);
    this.props.dispatch(pageAction.pageData(pageNameobj));
  }

  componentDidMount() {
    console.log('in checkin gatepass component');
    const mediaMatch = window.matchMedia('(max-width: 900px)');
    this.setState({mobile: mediaMatch.matches})
  }

  componentWillMount() {
    this.getIpAddress();
    this.props.dispatch(
      checkinAction.checkinData(
        getData('configureData')
      )
    );
    const dataObj = {
      guard: true
    };
    this.props.dispatch(guardAction.guardData(dataObj));
  }

  getIpAddress() {
    const language = LanguageUtils.getLangType(this.props);
    spinnerService.show(spinnerName.LOGIN_SPINNER);
    CallApi('GET', CONFIG.getIp, {}, {}, language, "getIp", this);
  }

  getIp = response => {
    this.setState({
      device_id: response.ip
    });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  // openInternalAccessModal() {
  //   this.setState({ internalAccessModalIsOpen: true });
  // }

  // closeInternalAccessModal() {
  //   this.setState({
  //     internalAccessModalIsOpen: false,
  //     accessCode: ''
  //   });
  // }

  // handleAccessChange(event) {
  //   this.setState({
  //     [event.target.name]: event.target.value
  //   });
  // }

  handleSubmit(event) {
    
    if (event !== "{enter}") {
      event.preventDefault();
    }
    const langType = LanguageUtils.getLangType(this.props);
    const shipmentObj = {
      reference_id: this.state.pickupNumber,
      language: !!this.props.languageData
        ? this.props.languageData.language
        : 'en',
      org_id: this.state.facilityId,
      device_id: this.state.device_id
    };
    if (this.state.pickupNumber) {
      const language = langType;
      spinnerService.show(spinnerName.LOGIN_SPINNER);
      CallApi('POST', CONFIG.queryShipment, {}, shipmentObj, language, "queryShipment", this);
    } else {
      AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.CorrectPickupNumber);
    }
  }

  queryShipment = response => {
    if (response.success) {
      response.result.reference_id = this.state.pickupNumber;
      this.props.dispatch(dataAction.masterData(response.result));
      this.props.history.push({
        pathname: ROUTES.APPOINTMENTDETAILS,
        state: {
          detail: ROUTES.DROPTRAILERS,
          reference_id: this.state.pickupNumber,
          didQueryReturnTrailerNumber: !!response.result.delivery[0][0].trailer_details.trailer_number ? true : false
        }
      });
    }
  }

  async handleModalSubmit() {
    const langType = LanguageUtils.getLangType(this.props);
    if (this.state.accessCode) {
      const internalAccessObj = {
        org_id: this.state.facilityId,
        reference_id: this.state.accessCode,
        language: this.props.languageData
          ? this.props.languageData.language
          : 'en',
        lane_no: this.state.laneNo
      };
      this.closeInternalAccessModal();
      const language = langType;
      spinnerService.show(spinnerName.LOGIN_SPINNER);
      await CallApi('POST', CONFIG.internalAccess, {}, internalAccessObj, language, "returnInternalAccess", this);
    } else {
      this.closeInternalAccessModal();
      AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.PleaseEnterAccessCode);
    }
  }

  returnInternalAccess = response => {
    if (response.success) {
      AlertModal.showAlert(response.message);
    }
  }

  dropEmptyTrailer() {
    this.setState({
      pageName: 'dropTrailer'
    });
    this.props.history.push(ROUTES.DROPTRAILERS);
  }

  deliveryReturn() {
    this.setState({
      pageName: 'deliveryReturn'
    });
    this.props.history.push(ROUTES.DELIVERYANDRETURN);
  }

  internalAccess() {
    // this.openInternalAccessModal();
    this.props.history.push(ROUTES.INTERNALACCESS);
  }

// ------------------------------------------------------------------------------------------

handleChange(event) {
  let inputVal = event.target.value;

  let updatedInputObj = {
    ...this.state.input,
    [this.state.inputName]: inputVal
  };

  this.setState(
    {
      input: updatedInputObj
    },
    () => {
      this.keyboardRef.keyboard.setInput(inputVal);
    }
  );
}

handleMobileChange(event) {
  this.setState({pickupNumber: event.target.value});
}

onChangeAll = inputObj => {
  this.setState({
    input: inputObj,
    pickupNumber: inputObj.input1
  });
};

onKeyPress = button => {
  /**
   * If you want to handle the shift and caps lock buttons
   */
  if (button === "{shift}" || button === "{lock}") this.handleShift();
  if (button === "{enter}") this.handleSubmit(button);
};

handleShift = () => {
  let layoutName = this.state.layoutName;

  this.setState({
    layoutName: layoutName === "default" ? "shift" : "default"
  });
};

setActiveInput = inputName => {
  this.setState(
    {
      inputName: inputName
    },
    () => { }
  );
};

  render() {
    const langType = LanguageUtils.getLangType(this.props);
    return (
      <div>
        <Header history={this.props.history} />
        
        {/* <CheckinAppointmentComponent /> */}
        <div className="body-container keyboard-container d-flex flex-column justify-content-center">
          <section>
            <div class="d-flex flex-column justify-content-center login-form">
              
            
            <div className="checkout-process-tabber">
              <div className="checkout-process-wrapper">
                <div className={`checkout-process-container container-586 ${this.state.mobile ? '' : ''}`}>
                  <h4 className='form-title mb-20'>{LANGCONSTANT[langType].STRINGS.PickupNumber}</h4>
                  <form>
                    <div className="form-group input-group">
                      <input
                        type="tel"
                        onFocus={() => this.setActiveInput("input1")}
                        value={this.state.pickupNumber}
                        placeholder={LANGCONSTANT[langType].STRINGS.PickupEnter}
                        name="pickupNumber"
                        className="form-control"
                        onChange={event => this.handleMobileChange(event)}
                        autoFocus={true}
                      />
                    </div>
                    <div className="d-flex justify-content-end align-items-end my-2">
                      
                      <a
                        href="javaScript:void(0)"
                        onClick={() => this.openModal()}
                        className="text-dark terms-condition-link"
                      >
                        <u>
                          {LANGCONSTANT[langType].LABELS.TermsAndConditions}
                        </u>
                      </a>
                    </div>
                    <div className='login-footer-btn text-center w-100'>
                      {/* <input
                        type="submit"
                        value={LANGCONSTANT[langType].BUTTONS.Submit}
                        className="btn btn-dark md-button"
                        onClick={ev => this.handleSubmit(ev)}
                      /> */}
                      <button 
                        className="btn btn-dark md-button"
                        onClick={ev => this.handleSubmit(ev)}
                      >
                        <span className="btn-text">Next <img style={{height: '2rem'}} src={rightArrow} /></span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            </div>
          </section>
        </div>
        <div>
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={() => this.closeModal()}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Term-Condition"
          >
            
              
            <div className="modal">
              <div className='terms-condition-box pt-5'>
              <h4 ref={subtitle => (this.subtitle = subtitle)}>
                {LANGCONSTANT[langType].LABELS.TermsAndConditions}
              </h4>
              <ol>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsOne}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsTwo}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsThree}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsFour}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsFive}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsSix}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsSeven}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsEight}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsNine}</li>
                <li>{LANGCONSTANT[langType].STRINGS.TrailerConditionsTen}</li>
                <li>
                  {LANGCONSTANT[langType].STRINGS.TrailerConditionsEleven}
                </li>
                <li>
                  {LANGCONSTANT[langType].STRINGS.TrailerConditionsTweleve}
                </li>
              </ol>
              <center>
                <button onClick={() => this.closeModal()} className='btn btn-dark md-button'>
                  {LANGCONSTANT[langType].BUTTONS.Ok}
                </button>
              </center>
            </div>
            </div>

          </Modal>
        </div>
        <div>
          <Modal
            isOpen={this.state.internalAccessModalIsOpen}
            onRequestClose={() => this.closeInternalAccessModal()}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Example Modal"
          >
            <div className="modal confirmation-modal-dialog">
              <div className="delivery-access-body">
                <h5 ref={subtitle => (this.subtitle = subtitle)}>
                  {LANGCONSTANT[langType].STRINGS.PleaseEnterAccessCode}
                </h5>
                <div className="form-group">
                  <input
                    type="text"
                    name="accessCode"
                    onChange={event => this.handleAccessChange(event)}
                    placeholder={LANGCONSTANT[langType].STRINGS.EnterAccessCode}
                    className="form-control"
                    autoFocus={true}
                  />
                </div>
                <div className="d-flex">
                  <button
                    className="btn btn-disable"
                    style={{ backgroundColor: '#797979' }}
                    onClick={() => this.closeInternalAccessModal()}
                  >
                    {LANGCONSTANT[langType].BUTTONS.Cancel}
                  </button>
                  <button
                    className="btn btn-submit"
                    onClick={() => this.handleModalSubmit()}
                  >
                    {LANGCONSTANT[langType].BUTTONS.Submit}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

CheckinGatePassComponent.propTypes = {
  dispatch: propTypes.func.isRequired
};

function mapStateToProps(state) {
  const data = state.data;
  return data;
}
export default connect(mapStateToProps)(CheckinGatePassComponent);

import React, { Component } from 'react';
import { CallApi } from '../../services/service';
import { CONFIG } from '../../configuration/config';
import * as ROUTES from '../../constants/routes';
import PageProgressComponent from '../../common/PageProgressComponent';
import { connect } from 'react-redux';
import * as dataAction from '../../redux/actions/deliveryReturnAction';
import propTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { spinnerName } from '../../constants/data';
import { spinnerService } from '../../services/spinner.service';
import * as LANGCONSTANT from '../../constants/language';
import LanguageUtils from '../../utils/LanguageUtils';
import Header from '../../common/Header';
import IdleTimerComponent from '../../common/idleCheck/IdleTimerComponent';
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import AlertModal from '../../common/modals/AlertModal';
import Modal from 'react-modal';
import { getData } from "../../containers/storageHandler";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)"
  }
};

class CheckoutAcknowledgementComponent extends Component{
    constructor(props){
        super(props);
        this.state ={
          pageNumber: 5,
          address: this.props.location.state.selectedTrailerDetail.address,
          appointmentTime: this.props.location.state.selectedTrailerDetail.app_time,
          trailerNumber: this.props.location.state.selectedTrailerDetail.trailer_number, //this.props.deliveryData ? this.props.deliveryData.delivery[this.state.index][0].trailer_details.trailer_number : "",
          appointmentNumber: this.props.location.state.selectedTrailerDetail.app_no, //this.props.deliveryData ? (this.props.deliveryData.delivery[this.state.index][0].trip_id || this.props.deliveryData.delivery[this.state.index][0].id || "") : "",
          sealNumber: this.props.location.state.selectedTrailerDetail.seal_no,
          carrierName: this.props.location.state.selectedTrailerDetail.carrier_name,//this.props.deliveryData ? this.props.deliveryData.delivery[this.state.index][0].carrier_name : "",
          carrierScac: this.props.location.state.selectedTrailerDetail.carrier_scac,
          driverName: this.props.location.state.selectedTrailerDetail.driver_name,
          mobileNumber: (this.props.location.state.selectedTrailerDetail.phone_no).toString(),//this.props.deliveryData ? this.props.deliveryData.delivery[this.state.index][0].trailer_details.driver_phone : "",
          customerName: this.props.location.state.selectedTrailerDetail.customer_name,
          deliveryNumber: this.props.location.state.selectedTrailerDetail.delivery_no,
          inputName: "input1",
          input:{},
          configurationData: getData("configureData")
        }
    }
    
    componentWillMount(){
      this.setState({
        input:{
          // input1: "test test test",
          input1: this.state.driverName ? this.state.driverName : "",
          input2: this.state.mobileNumber ? this.state.mobileNumber : "",
          input3: this.state.sealNumber ? this.state.sealNumber : ""
        }
      })
    }

    onKeyPress = button => {
      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift();
      if (button === "{enter}") this.verifyData();
    };

    handleChange(event) {
      let inputVal = event.target.value;
      let updatedInputObj = {
        ...this.state.input,
        [this.state.inputName]: inputVal
      };
  
      this.setState(
        {
          input: updatedInputObj
        },
        () => {
          this.keyboardRef.keyboard.setInput(inputVal);
        }
      );
    }

    setActiveInput = inputName => {
      this.setState(
        {
          inputName: inputName
        },
        () => { }
      );
    };

    onChangeAll = (inputObj, inputName) => {
      if(inputName === "input1"){
        this.setState({
          input: {
            input1: inputObj.input1,
            input2: this.state.mobileNumber,
            input3: this.state.sealNumber
          },
          driverName: inputObj.input1
        });
      }
      else if(inputName === "input2"){
        this.setState({
          input: {
            input1: this.state.driverName,
            input2: inputObj.input2,
            input3: this.state.sealNumber
          },
          mobileNumber: inputObj.input2
        });
      }
      else if(inputName === "input3"){
        this.setState({
          input: {
            input1: this.state.driverName,
            input2: this.state.mobileNumber,
            input3: inputObj.input3
          },
          sealNumber: inputObj.input3
        });
      }
    };

    back() {
      this.props.history.goBack();
      // this.props.history.push({
      //   pathname: ROUTES.SEALDETAIL,
      //   state: {
      //     selectedTrailerDetail: this.props.location.state.selectedTrailerDetail
      //   }
      // });
    }

    handleMobileChange(event) {
      this.setState({
        [event.target.name]: event.target.value
      });
    }
  
    verifyData() {
      const langType = LanguageUtils.getLangType(this.props);
      if (this.state.mobileNumber && this.state.driverName && this.state.sealNumber) {
        if (true) {
          
          this.props.location.state.selectedTrailerDetail["driver_name"] = this.state.driverName;
          this.props.location.state.selectedTrailerDetail["phone_no"] = '123123123'// this.state.mobileNumber;
          this.props.location.state.selectedTrailerDetail["seal_no"]= this.state.sealNumber;
          
          this.props.history.push({
            pathname: ROUTES.SIGNDATA,
            state: {
              selectedTrailerDetail: this.props.location.state.selectedTrailerDetail
            }
          });
        } else {
          AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.ValidMobile);
        }
      } else {
        AlertModal.showAlert(LANGCONSTANT[langType].STRINGS.EnterDetails);
      }
    }

    render() {
        const langType = LanguageUtils.getLangType(this.props);
        return (
          <div>
            {/* <IdleTimerComponent /> */}
            <Header history={this.props.history} />
            <div className="body-container  keyboard-container">
              <section>
                <div className="checkout-process-tabber">
                  <div className="checkout-process-wrapper">
                    <div className="checkout-process-container container-890">
                      <div className="white-box confirmation-detail d-flex flex-wrap">
                        <div className="confirmation-trailer-detail">
                            <h4>{LANGCONSTANT[langType].LABELS.TrailerDetails}</h4>
                            <ul>
                              <li>
                                <strong>{LANGCONSTANT[langType].LABELS.TrailerNumber}</strong><span>{
                                  this.state.trailerNumber}</span>
                              </li>
                              <li>
                                <strong>{LANGCONSTANT[langType].LABELS.CarrierName}</strong><span>{this.state.carrierName}</span>
                              </li>
                              <li>
                                    <strong>{LANGCONSTANT[langType].LABELS.DriverName}</strong>
                                    <div className="form-group">
                                    <input
                                      type='text'
                                      onFocus={() => this.setActiveInput("input1")}
                                      // value='test test'
                                      value={this.state.input["input1"] || ""}
                                      placeholder={LANGCONSTANT[langType].LABELS.DriverName}
                                      className="form-control-checkout-acknowledge"
                                      name='driverName'
                                      onChange={(event) => this.handleChange(event)}
                                      autoFocus={true}
                                    />
                                  </div>
                                </li>
                                {/* below li commented out at KC's request. Phone number was showing incorrectly, may uncomment when data issue is resolved */}
                                {/* <li>
                                  <strong>{LANGCONSTANT[langType].LABELS.PhoneNumber}</strong>
                                  <div className="form-group">
                                    <input
                                      type='tel'
                                      onFocus={() => this.setActiveInput("input2")}
                                      value={this.state.input["input2"] || ""}
                                      placeholder={LANGCONSTANT[langType].LABELS.PhoneNumber}
                                      maxLength='10'
                                      className="form-control-checkout-acknowledge"
                                      name='mobileNumber'
                                      />
                                  </div>
                                </li> */}
                                <li>
                                <strong>{LANGCONSTANT[langType].LABELS.SealNumber}</strong>
                                  <div className="form-group">
                                    <input
                                      type='text'
                                      onFocus={() => this.setActiveInput("input3")}
                                      value={this.state.input["input3"] || ""}
                                      placeholder={LANGCONSTANT[langType].LABELS.SealNumber}
                                      className="form-control-checkout-acknowledge"
                                      name='driverName'
                                      onChange={(event) => this.handleChange(event)}
                                      autoFocus={true}
                                    />
                                  </div>
                                </li>
                            </ul>
                        </div>
                        <div className="confirmation-appointment-detail">
                            <h4>{LANGCONSTANT[langType].LABELS.AppointmentDetails}</h4>
                              <ul>
                                  <li>
                                    <strong>{LANGCONSTANT[langType].LABELS.DestinationLocation}</strong>
                                    {this.state.address}
                                  </li>
                                  <li>
                                    <strong>{LANGCONSTANT[langType].LABELS.AppointmentNumber}</strong><span>{this.state.appointmentNumber}</span>
                                  </li>
                                  <li>
                                    <strong>{LANGCONSTANT[langType].LABELS.AppointmentTime}</strong><span>{this.state.appointmentTime}</span>
                                  </li>
                                  <li>
                                    <strong>{LANGCONSTANT[langType].LABELS.DeliveryNumber}</strong>{this.state.deliveryNumber}
                                  </li>
                                  <li>
                                    <strong>{LANGCONSTANT[langType].LABELS.CustomerName}</strong><span>{this.state.customerName}</span>
                                  </li>
                              </ul>
                              <div className="form-group">
                                <input type='button' className="btn btn-disable" onClick={() => this.back()} value={LANGCONSTANT[langType].BUTTONS.Back} />
                                <input type='button' className="btn btn-submit" onClick={() => this.verifyData()} value={LANGCONSTANT[langType].BUTTONS.Confirm} />
                              </div>  
                        </div> 
                      </div>
                    </div>
                    <div className="keyboard d-flex">
                        <Keyboard
                          ref={r => (this.keyboardRef = r)}
                          inputName={this.state.inputName}
                          syncInstanceInputs={true}
                          display={{
                            "{bksp}": "backspace",
                            "{enter}": " enter",
                            "{space}": "space"
                          }}
                          layout={{
                            default: [
                              // "1 2 3 4 5 6 7 8 9 0 - = {bksp}",
                              "q w e r t y u i o p {bksp}",
                              "a s d f g h j k l {enter}",
                              "z x c v b n m",
                              "{space}"
                            ]
                          }}
                          onChangeAll={inputObj => this.onChangeAll(inputObj, this.state.inputName)}
                          onKeyPress={button => this.onKeyPress(button)}
                        />
                        <div className="keyboard2-container">
                          <Keyboard
                            syncInstanceInputs={true}
                            inputName={this.state.inputName}
                            baseClass={"keyboard2"}
                            layout={{
                              default: ["1 2 3", "4 5 6", "7 8 9", "0"]
                            }}
                            onChangeAll={(inputObj) => this.onChangeAll(inputObj, this.state.inputName)}
                            onKeyPress={button => this.onKeyPress(button)}
                          />
                        </div>
                      </div>
                  </div>
                  {/* <div className="checkout-process-wrapper">
                    <div className="checkout-process-container container-890 white-box delivery-detail"> */}
                      {/* <h4>{LANGCONSTANT[langType].STRINGS.DetailsTrailer}</h4> */}
                      {/* <form className="d-flex flex-wrap">
                        <div className="form-group">
                          <input
                            type='text'
                            onFocus={() => this.setActiveInput("input1")}
                            value={this.state.input["input1"] || ""}
                            placeholder={LANGCONSTANT[langType].LABELS.DriverName}
                            className="form-control"
                            name='driverName'
                            onChange={(event) => this.handleChange(event)}
                            autoFocus={true}
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type='tel'
                            onFocus={() => this.setActiveInput("input2")}
                            value={this.state.input["input2"] || ""}
                            placeholder={LANGCONSTANT[langType].LABELS.PhoneNumber}
                            maxLength='10'
                            className="form-control"
                            name='mobileNumber'
                            onChange={(event) => this.handleMobileChange(event)}
                            onKeyPress={(event) => this.checkNumber(event)} />
                        </div>
                        <div className="form-group d-flex">
                          <input
                            type='text'
                            onFocus={() => this.setActiveInput("input3")}
                            value={this.state.input["input3"] || ""}
                            placeholder={LANGCONSTANT[langType].LABELS.SealNumber}
                            className="form-control"
                            name='driverName'
                            onChange={(event) => this.handleChange(event)}
                            autoFocus={true}
                          />
                        </div>
                        <div className="text-right btn-wrapper ">
                              <input type='button' className="btn btn-disable" onClick={() => this.back()} value={LANGCONSTANT[langType].BUTTONS.Back} />
                              <input type='button' className="btn btn-submit" onClick={() => this.verifyData()} value={LANGCONSTANT[langType].BUTTONS.Confirm} />
                        </div>          
                      </form> */}
                    {/* </div> */}
                  {/* </div> */}
              </div>

              
              </section>
              <div className="progress-bar-bottom">
                <PageProgressComponent
                  pageName={LANGCONSTANT[langType].LABELS.CheckOut}
                  pageNumber={this.state.pageNumber}
                  totalPage={6}
                  pageNameList={
                    [
                      LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ExitGate,
                      LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanGatePass,
                      LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ConfirmDetails,
                      LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.ScanSeal,
                      LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.Acknowledgement,
                      LANGCONSTANT[LanguageUtils.getLangType(this.props)].LABELS.Sign
                    ]
                  }
                />
              </div>
            </div>
          </div>
        );
      }
}


CheckoutAcknowledgementComponent.propTypes = {
    actions: propTypes.object.isRequired
  };
  
  function mapStateToProps(state) {
    const data = state.data;
    return data;
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(dataAction, dispatch)
    };
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(CheckoutAcknowledgementComponent);
  
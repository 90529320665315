import AlertModal from '../common/modals/AlertModal';
import { CONFIG } from "../configuration/config";
import * as ROUTES from '../constants/routes';
import { spinnerName } from '../constants/data';
import { spinnerService } from './spinner.service';
import { setData, getData, removeData } from "../containers/storageHandler";

export const CallApi = async (method = "GET", endpoint, customHeaders = {}, payload, language = 'ENG', returnMethod, obj) => {
  const ele = document.getElementById('page-loading');
  ele.classList.remove('d-none')
  let headers = Object.assign({}, {
    'accept': 'application/json',
    "Content-Type": "application/json",
    // token: 'abc',
    app_version: '1.0.0',
    language: language,
    device: "kioskWebApp"
  });
  if (endpoint !== CONFIG.authorizeSso) {
    headers.authorization = `Bearer ${getData('auth')}`;
  }
  if (method === "GET") {
    if (endpoint === CONFIG.getIp) {
      headers = customHeaders;
    }
    var request = new Request(`${endpoint}`, {
      method: method,
      headers: new Headers(headers)
    });
  } else {
    var request = new Request(`${endpoint}`, {
      method: method,
      headers: new Headers(headers),
      body: JSON.stringify(payload)
    });
  }
  await fetch(request)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response);
      }
      return Promise.reject(new Error(response.statusText));
    }).then(response => {
      return response.json();
    }).then((response) => {
      ele.classList.add('d-none')
      spinnerService.hide(spinnerName.LOGIN_SPINNER);
      if (response.responseCode !== 401) {
        if (endpoint !== CONFIG.getIp) {
          if (response.responseCode === 200) {
            eval("obj." + returnMethod + "(response)");
          } else {
            AlertModal.showAlert(response.message);
            // navigateToHome(obj);
          }
        } else {
          if (!!returnMethod) {
            eval("obj." + returnMethod + "(response)");
          }
        }
      } else {
        removeData("isLogin");
        obj.props.history.push(ROUTES.LANDING);
        AlertModal.showAlert(response.message);
      }
    }
    )
    .catch(error => {
      spinnerService.hide(spinnerName.LOGIN_SPINNER);
      AlertModal.showAlert(error.message);
    });
};

function navigateToHome(obj) {
  if (!!getData('pageName')) {
    const pagename = getData('pageName').pageName;
    if (pagename === 'checkin') {
      obj.props.history.push(ROUTES.LANGUAGE);
    } else if (pagename === 'checkout') {
      obj.props.history.push(ROUTES.LANGUAGE);
    } else {
      obj.props.history.push(ROUTES.CONFIGURATION);
    }
  } else {
    obj.props.history.push(ROUTES.CONFIGURATION);
  }
};